.btnAvatarBackgroundWrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 30px;
}
.btnAvatar {
  width: 150px;
  min-height: 150px;
  border-radius: 50%;
}
.btnAvatarBackdrop {
  width: 150px;
  min-height: 150px;
  border-radius: 50%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnBackground {
  width: 300px;
  min-height: 150px;
  border-radius: 30px !important;
}
.btnBackgroundBackdrop {
  width: 300px;
  min-height: 150px;
  border-radius: 25px;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}