.headerWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 65px;
  background-color: #fff;
  border-bottom: 1px #ddd solid;
  padding: 0 10px;
  justify-content: space-between;
}

.menuWrapper {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .menuItem {
    padding: 10px;
    position: relative;
    cursor: pointer;
    .badge {
      position: absolute;
      top: 8px;
      right: 4px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      background-origin: padding-box;
      color: #fff;
      min-width: 14px;
      height: 14px;
      background-color: #eb5757;
      border-radius: 50%;
    }
    &:last-child img {
      border-radius: 50%;
      border: 1px solid #c4c4c4;
    }
  }
}

.webName {
  margin-left: 10px;
  font-size: 25px;
  font-weight: 600;
  color: #6be9e7;
  text-shadow: 0 2px 4px rgba(107, 233, 231, 0.2);
}

.option {
  display: flex;
  align-items: center;
}

.language {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 30px;
    height: auto;
    margin-right: 10px;
  }
  &__select {
    min-width: 120px;
  }
}

.placeholder {
  color: rgba(0,0,0,.85);
  font-size: 14px;
}