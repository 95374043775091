.uploadCustom {
  :global {
    .ant-upload.ant-upload-select-picture-card {
      width: 100%;
      height: auto;
      margin-right: 0;
      margin-bottom: 0;
      background-color: transparent;
      border: 0;
    }
  }
}
