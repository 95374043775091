.container {
  padding: 20px;
}

.itemTable {
  border-radius: 20px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgItemTable {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: #d3d3d3;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
  }
}
