.container {
  padding: 20px;
}

.filterContainer {
  max-width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  .select {
    min-width: 240px;
    margin-left: 20px;
  }
}

.uploadContainer {
  .select {
    min-width: 250px;
    .placeholder {
      color: rgba(0,0,0,.85);
      font-size: 14px;
    }
  }
}

.selectEnvForm {
  min-width: 100px;
  .placeholder {
    color: rgba(0,0,0,.85);
    font-size: 14px;
  }
}

.footerModal {
  text-align: right;
}

.languageModal {
  :global {
    .ant-form-item-label {
      min-width: 150px;
      text-align: left;
    }
  }
}

.action {
  display: flex;
  justify-content: space-between;
}

.env {
  color: #096dd9;
  background: #e6f7ff;
  border-color: #91d5ff;
  padding: 2px 6px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}