.container {
  padding: 20px;
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.listenIcon {
  cursor: pointer;
  img {
    width: auto;
    height: 70px;
  }
  .imgUndefined {
    width: 139px;
    height: 70px;
    background-color: #d3d3d3;
  }
}

.streamAudio {
  position: relative;
  audio {
    width: 100%;
  }
}

.audioModal {
  padding: 0 !important;
  top: calc(50vh - 49px) !important;
  :global {
    .ant-modal-body {
      flex: 1;
    }
    .ant-modal-content {
      box-shadow: none;
    }
  }
}

.reactPlayer {
  width: 100%;
  height: 50px;
  audio {
    width: 100% !important;
    height: 100% !important;
  }
}
