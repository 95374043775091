.ml-10 {
  margin-left: 10px;
}

.ml-5 {
  margin-left: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-5 {
  margin-top: 5px;
}

.mw-150 {
  min-width: 150px;
}

.white-pre-live {
  white-space: pre-line;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex;
}

.conversationScrollContainer {
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background: #888888;
      border-radius: 50px;
    }
  }
}