.spin-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.251);
  z-index: 9999;
}

.spin-loading-screen {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #a6e8da;
}

.textRequired {
  color: red;
  font-size: 12px;
}