.container {
  padding: 20px;
}

.footerModal {
  text-align: right;
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.keywordSearch {
  min-width: 300px;
}